import axios from "axios";
import {
  CloudinaryDeleteFileResponseType,
  GetCloudinarySignedUrlPropsType,
  GetCloudinarySignedUrlReturnType,
  UploadCloudinaryParamsType,
  UploadToCloudinaryResponseType,
} from "./type";
import { andianaAxios } from "@business-layer/services/config/axios";

export * from "./type";
class ImageStorageServices {
  getCloudinarySignedUrl = async ({
    token,
    ...data
  }: GetCloudinarySignedUrlPropsType) => {
    return await andianaAxios<GetCloudinarySignedUrlReturnType>({
      method: "POST",
      url: "/media/get-signed-url",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  uploadFileToCloudinary = async ({
    uploadUrl,
    form,
  }: UploadCloudinaryParamsType): Promise<UploadToCloudinaryResponseType> => {
    const response = await axios(uploadUrl, {
      method: "POST",
      data: form,
    });

    return response.data;
  };

  deleteFileFromCloudinary = async (
    token: string,
    fileName: string
  ): Promise<CloudinaryDeleteFileResponseType> => {
    return await andianaAxios({
      method: "DELETE",
      url: "/media/delete-single-file",
      params: {
        fileName,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

export const imageStorageService = new ImageStorageServices();
