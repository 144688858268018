import { useGetSession } from "@business-layer/business-logic/lib/auth/hooks/useGetSession";
import { useBoolean } from "@business-layer/business-logic/common/useBoolean";
import {
  bluelyraService,
  CreateNewPlaylistPropsType,
} from "@business-layer/services/lib/bluelyraService";
import { IBluelyraPlaylist } from "@business-layer/services/entities";
import { MediaPath } from "../../image-storage/constants";
import { generateUuid } from "@utils/helpers/uuid.helper";
import { useImageProcessor } from "@business-layer/business-logic/common/useImageProcessor";
import { useDispatch } from "react-redux";
import {
  deleteUserPlaylist,
  addUserPlaylist,
  setCurrentPlaylist,
} from "../context/video-slice";
import { BluelyraVideoContextType } from "../context/type";
import { useCloudinary } from "../../image-storage/hooks/useCloudinary";

export const useBluelyraPlaylist = () => {
  const { token } = useGetSession();
  const dispatch = useDispatch();
  const { processImage } = useImageProcessor();
  const {
    value: isLoading,
    setTrue: setIsLoadingTrue,
    setFalse: setIsLoadingFalse,
  } = useBoolean(false);
  const { uploadMedia, deleteMedia } = useCloudinary();

  async function create({
    imageFile,
    ...payload
  }: Omit<CreateNewPlaylistPropsType, "token" | "image"> & {
    imageFile: File;
  }) {
    let uploadedFilePublicId: string[] = [];
    try {
      const { tiny, standard, thumbnail } = await processImage(imageFile);
      setIsLoadingTrue();
      const imageId = generateUuid();
      const [
        { data: tinyUploaded, error: uploadTinyError },
        { data: thumbnailUploaded, error: uploadThumbnailError },
        { data: standardUploaded, error: uploadStandardError },
      ] = await Promise.all([
        uploadMedia({
          path: MediaPath.BluelyraPlaylistThumbnail,
          name: `tiny-${imageId}`,
          type: tiny.file.type,
          file: tiny.file,
        }),
        uploadMedia({
          path: MediaPath.BluelyraPlaylistThumbnail,
          name: `thumbnail-${imageId}`,
          type: thumbnail.file.type,
          file: thumbnail.file,
        }),
        uploadMedia({
          path: MediaPath.BluelyraPlaylistThumbnail,
          name: `standard-${imageId}`,
          type: standard.file.type,
          file: standard.file,
        }),
      ]);
      tinyUploaded && uploadedFilePublicId.push(tinyUploaded.public_id);
      thumbnailUploaded &&
        uploadedFilePublicId.push(thumbnailUploaded.public_id);
      standardUploaded && uploadedFilePublicId.push(standardUploaded.public_id);

      if (
        uploadTinyError ||
        uploadThumbnailError ||
        uploadStandardError ||
        !tinyUploaded ||
        !thumbnailUploaded ||
        !standardUploaded
      ) {
        // TODO: ADDLANG
        return {
          error: new Error("ERROR"),
        };
      }

      const playlist = await bluelyraService.createPlaylist({
        token,
        ...payload,
        image: {
          format: {
            tiny: {
              url: tinyUploaded.url,
              pathName: tinyUploaded.secure_url,
              size: imageFile.size,
              width: tiny.width,
              height: tiny.height,
            },
            thumbnail: {
              url: thumbnailUploaded.url,
              pathName: thumbnailUploaded.secure_url,
              size: imageFile.size,
              width: thumbnail.width,
              height: thumbnail.height,
            },
            standard: {
              url: standardUploaded.url,
              pathName: standardUploaded.secure_url,
              size: imageFile.size,
              width: standard.width,
              height: standard.height,
            },
          },
          thumbnailUrl: thumbnailUploaded.url,
          standardUrl: standardUploaded.url,
          standardSize: standard.size,
        },
      });

      dispatch(addUserPlaylist(playlist));
      return {
        error: null,
      };
    } catch (error) {
      if (uploadedFilePublicId.length) {
        await Promise.all(
          uploadedFilePublicId.map((path) => deleteMedia(path))
        );
      }
      return {
        error: error as Error,
      };
    } finally {
      setIsLoadingFalse();
    }
  }

  async function deletePlaylist(playlist: IBluelyraPlaylist) {
    try {
      setIsLoadingTrue();
      await bluelyraService.deletePlaylist({ token, playlistId: playlist.id });
      await Promise.all(
        Object.values(playlist.image.format).map(({ pathName }) => {
          const splitedPathName = pathName.split("/");
          return deleteMedia(splitedPathName[splitedPathName.length - 1]);
        })
      );
      dispatch(deleteUserPlaylist(playlist.id));
      return {
        error: null,
      };
    } catch (error) {
      return {
        error: error as Error,
      };
    } finally {
      setIsLoadingFalse();
    }
  }

  function updateCurrentPlaylist(
    playlist: BluelyraVideoContextType["currentPlaylist"]
  ) {
    dispatch(setCurrentPlaylist(playlist));
  }

  return {
    create,
    deletePlaylist,
    updateCurrentPlaylist,
    isLoading,
  };
};
