import { andianaAxios } from "@business-layer/services/config/axios";
import {
  AddVideoToPlaylistPropsType,
  CreateNewPlaylistPropsType,
  DeletePlaylistPropsType,
  DeleteVideoFromPlaylistPropsType,
  GetAllPlaylistResponseType,
  GetTrendingYoutubeVideosPropsType,
  SearchYoutubeVideosPropsType,
  SearchYoutubeVideosResponseType,
} from "./type";
import { CommonMessageResponseType } from "../type";
import { IYouTubeSearchResponse } from "@business-layer/services/entities";

export * from "./type";

class BluelyraService {
  static instance: any;

  constructor() {
    if (BluelyraService.instance) {
      return BluelyraService.instance;
    }
    BluelyraService.instance = this;
  }

  getAllPlaylists = async (token: string | null) => {
    const response = await andianaAxios<GetAllPlaylistResponseType>({
      method: "GET",
      url: "/bluelyra/playlists",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  createPlaylist = async ({
    token,
    ...payload
  }: CreateNewPlaylistPropsType) => {
    const response = await andianaAxios<CommonMessageResponseType>({
      method: "POST",
      url: "/bluelyra/playlists/create",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    });
    return response.data;
  };

  deletePlaylist = async ({ token, ...payload }: DeletePlaylistPropsType) => {
    const response = await andianaAxios<CommonMessageResponseType>({
      method: "DELETE",
      url: "/bluelyra/playlists",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: payload,
    });
    return response.data;
  };

  addVideoToPlaylist = async ({
    token,
    ...payload
  }: AddVideoToPlaylistPropsType) => {
    const response = await andianaAxios<CommonMessageResponseType>({
      method: "POST",
      url: "/bluelyra/playlists/videos/add",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    });
    return response.data;
  };

  deleteVideoFromPlaylist = async ({
    token,
    ...payload
  }: DeleteVideoFromPlaylistPropsType) => {
    const response = await andianaAxios<CommonMessageResponseType>({
      method: "DELETE",
      url: "/bluelyra/playlists/videos/delete",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: payload,
    });
    return response.data;
  };

  searchYoutubeVideos = async ({
    token,
    ...params
  }: SearchYoutubeVideosPropsType): Promise<SearchYoutubeVideosResponseType> => {
    if (!token) {
      throw new Error("Unauthorized");
    }
    const response = await andianaAxios<SearchYoutubeVideosResponseType>({
      method: "GET",
      url: "/bluelyra/youtube/search-videos",
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  getTrendingVideos = async ({
    token,
  }: GetTrendingYoutubeVideosPropsType): Promise<IYouTubeSearchResponse> => {
    if (!token) {
      throw new Error("Unauthorized");
    }
    const response = await andianaAxios<IYouTubeSearchResponse>({
      method: "GET",
      url: "https://www.googleapis.com/youtube/v3/videos",
      params: {
        part: "snippet,contentDetails,statistics",
        chart: "mostPopular",
        maxResults: 50,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };
}

export const bluelyraService = new BluelyraService();
