export const ENV = {
  app: {
    baseUrl: process.env.REACT_APP_BASE_URL ?? "",
  },
  hiddenValley: {
    encryptSecret: process.env.REACT_APP_HIDDEN_VALLEY_ENCRYPT_SECRET ?? "",
  },
  server: {
    baseUrl: process.env.REACT_APP_SERVER_BASE_URL ?? "",
  },
  googleOAuth: {
    baseUrl: process.env.REACT_APP_GOOGLE_OAUTH_BASE_URL ?? "",
    clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? "",
    clientSecret: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_SECRET ?? "",
  },
};
