import { ENV } from "@constants/env.constants";
import axiosLib from "axios";

export const andianaAxios = axiosLib.create({
  baseURL: ENV.server.baseUrl,
});

export const googleOAuthAxios = axiosLib.create({
  baseURL: ENV.googleOAuth.baseUrl,
});
