import { imageStorageService } from "@business-layer/services/lib/imageStorageService";
import { useBoolean } from "@business-layer/business-logic/common/useBoolean";
import { useGetSession } from "../../auth/hooks/useGetSession";

export type mediaUploadType = {
  path: string;
  name: string;
  type: string;
  file: File;
};

export const useCloudinary = () => {
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const { token } = useGetSession();

  /**
   *
   * @param path Media path. E.g /folder1/folder2
   * @param name Media name
   * @param type Mime type
   * @returns
   */
  const uploadMedia = async ({ path, name, type, file }: mediaUploadType) => {
    try {
      if (!token) {
        return {
          error: new Error("Unauthorized"),
          data: null,
        };
      }
      setTrue();
      const extension = type.split("/")[1];

      const { data: responseData } =
        await imageStorageService.getCloudinarySignedUrl({
          token,
          path,
          name,
          extension,
        });

      const form = new FormData();
      form.append("file", file);
      const uploadResponseData =
        await imageStorageService.uploadFileToCloudinary({
          uploadUrl: responseData.uploadUrl,
          form,
        });

      setFalse();
      return {
        data: uploadResponseData,
        error: null,
      };
    } catch (error) {
      console.error(error);
      return {
        error: error as Error,
        data: null,
      };
    }
  };

  const deleteMedia = async (fileName: string) => {
    try {
      if (!token) {
        return {
          error: new Error("Unauthorized"),
          data: null,
        };
      }
      console.log(fileName);
      setTrue();
      const deleteResponse = await imageStorageService.deleteFileFromCloudinary(
        token,
        fileName
      );
      setFalse();
      return {
        data: deleteResponse,
        error: null,
      };
    } catch (error) {
      return {
        error: error as Error,
        data: null,
      };
    }
  };

  return {
    uploadMedia,
    deleteMedia,
    isLoading,
  };
};
