import { bluelyraService } from "@business-layer/services/lib/bluelyraService";
import { useGetSession } from "../../auth/hooks/useGetSession";
import { useBoolean } from "@business-layer/business-logic/common/useBoolean";

const DEFAULT_PARAMS = {
  maxResults: 30,
  part: "snippet",
};

export const useSearchYoutubeVideos = () => {
  const {
    value: isLoading,
    setTrue: setLoadingTrue,
    setFalse: setLoadingFalse,
  } = useBoolean(false);
  const { token } = useGetSession();

  async function search(keys: string) {
    try {
      setLoadingTrue();
      const response = await bluelyraService.searchYoutubeVideos({
        ...DEFAULT_PARAMS,
        search: keys,
        token,
      });
      return {
        data: response.items,
        error: null,
      };
    } catch (error) {
      console.error(error);
      return {
        data: null,
        error: error as Error,
      };
    } finally {
      setLoadingFalse();
    }
  }

  return {
    search,
    isLoading,
  };
};
