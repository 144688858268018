import { useTranslation } from "@business-layer/business-logic/common/useTranslation";
import { useAccessToken } from "./useAccessToken";
import { useRefreshToken } from "./useRefreshToken";
import { useBoolean } from "@business-layer/business-logic/common/useBoolean";
import {
  authService,
  RegisterParamsType,
} from "@business-layer/services/lib/authService";
import { ContextDataType } from "@business-layer/business-logic/configs";
import { AuthContextType } from "../context/type";
import { useSelector } from "react-redux";

export const useAuth = () => {
  const { translate: trans } = useTranslation();
  const { setToken, deleteToken } = useAccessToken();
  const { setRefreshToken, deleteRefreshToken } = useRefreshToken();
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const { token } = useSelector<ContextDataType, AuthContextType>(
    (state) => state.auth
  );

  const login = async (email: string, password: string) => {
    try {
      setTrue();
      const { token, refreshToken, message } = await authService.login({
        email,
        password,
      });

      setToken(token);
      setRefreshToken(refreshToken);

      return {
        data: {
          message: trans("SERVER", message as any),
        },
        error: null,
      };
    } catch (error: any) {
      return {
        data: null,
        error: error?.response?.data?.message
          ? new Error(trans("SERVER", error.response.data.message))
          : error?.message,
      };
    } finally {
      setFalse();
    }
  };

  const register = async (props: RegisterParamsType) => {
    try {
      setTrue();
      const { token, refreshToken, message } = await authService.register(
        props
      );
      setToken(token);
      setRefreshToken(refreshToken);
      return {
        data: {
          message: trans("SERVER", message as any),
        },
        error: null,
      };
    } catch (error: any) {
      return {
        data: null,
        error: new Error(trans("SERVER", error.message)),
      };
    } finally {
      setFalse();
    }
  };

  const logout = async () => {
    try {
      deleteToken();
      deleteRefreshToken();
      token && authService.logout(token);
      return { error: null };
    } catch (error) {
      return { error };
    }
  };

  return {
    login,
    register,
    logout,
    isLoading,
  };
};
